import TableContent from "./TableContent";
import "./table.css";

const RecapTable = (props) => {
  return (
    <div className="table-section-container" style={{ marginTop: "60px" }}>
      <div className="recap-table-heading" style={{ marginTop: "60px" }}>
        Monthly Insights
      </div>
      <div className="list-description">
        Select a month to see its Statement Summary
      </div>
      <TableContent {...props} />
    </div>
  );
};

export default RecapTable;
