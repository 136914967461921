import formattedAmount from "util/formattedString";

// Helper to get baseId from local storage
const getBaseId = () => {
  return localStorage.getItem("baseId");
};

export const data = (vs) => {
  // Get baseId from local storage
  const baseId = getBaseId();
  const isRatePerGuestBooking = baseId === "appn8T3UykPjpTnV2"; // Check which bas

  return [
    {
      key: 1,
      title: "Guest Bookings",
      card: [
        {
          cardtitle: "# Bookings",
          cardvalue: vs?.["Guest Bookings"] || "0",
        },
        ...(vs?.["Guest Bookings"]
          ? [
              {
                cardtitle: "Total nights",
                cardvalue: vs?.["Guest Nights"],
              },
              {
                cardtitle: "Rate per booking",
                cardvalue: isRatePerGuestBooking
                  ? formattedAmount(vs?.["Rate per Guest Booking"], true) || "-"
                  : formattedAmount(vs?.["Guest Booking Rate"], true) || "-",
              },
              {
                cardtitle: "Rate per night",
                cardvalue:
                  formattedAmount(vs?.["Guest Nightly Rate"], true) || "-",
              },
            ]
          : []),
      ],
    },
    {
      key: 2,
      title: "Owner Bookings",
      card: [
        {
          cardtitle: "# Stays",
          cardvalue: vs?.["Owner Stays"] || "0",
        },
        ...(vs?.["Owner Stays"] && vs?.["Owner Stays"] >= 1
          ? [
              {
                cardtitle: "Total nights",
                cardvalue: vs?.["Owner Nights"] || "0",
              },
              {
                cardtitle: "Booking value(s)",
                cardvalue:
                  formattedAmount(vs?.["Val of Owner Bookings"], true) || "-",
              },
              {
                cardtitle: "Value per night",
                cardvalue:
                  formattedAmount(vs?.["Owner Nightly Rate"], true) || "-",
              },
            ]
          : []),
      ],
    },
    {
      key: 3,
      title: "Monthly Rental Income",
      card: [
        {
          cardtitle: "Gross Revenue",
          cardvalue: formattedAmount(vs?.["MonthlyRevenue"], true) || "-",
        },
        {
          cardtitle: "Net of Taxes",
          cardvalue: formattedAmount(vs?.["Net of Taxes"], true) || "-",
        },
        {
          cardtitle: "Revenue Split (70%)",
          cardvalue:
            formattedAmount(vs?.["Revenue Split  ( 𝟩𝟢﹪)"], true) || "-",
        },
      ],
    },
  ];
};
