import SingleCard from "./SingleCard";
import "../summary.css";
import formattedAmount from "util/formattedString";
import getPercentValue from "util/getPercentage";

const FirstRow = ({ data }) => {
  const formatValue = (value, isCurrency = false) => {
    if (value === null || value === undefined || value === "") {
      return isCurrency ? "$0" : "0%";
    }
    return isCurrency ? formattedAmount(value, true) : getPercentValue(value);
  };

  return (
    <div className="summary-first-row">
      <SingleCard
        value={formatValue(data?.["Gross Revenue"], true)}
        title="Gross Revenue"
      />
      <SingleCard
        value={formatValue(data?.["Net of Taxes"], true)}
        title="Net of Taxes"
      />
      <SingleCard
        value={formatValue(data?.["Owner Revenue"], true)}
        title="Owner Revenue"
      />
      <SingleCard
        value={formatValue(data?.["Occupancy Rate"])}
        title="Occupancy Rate"
      />
    </div>
  );
};

export default FirstRow;
