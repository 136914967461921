import Airtable from "airtable";
import { requestCompleted, requestStart } from "..";

const apiKey =
  "patfIHv7xmjArvAKG.8821159aa381f9044feeb21a631149370f60be5e8cbfe11ffde3ccec187f33ca";

const fetchBase = () => {
  const baseId = localStorage.getItem("baseId");
  if (apiKey && baseId) {
    console.log("Fetching base with explicit base ID");
    console.log("API Key:", apiKey);
    console.log("Base ID:", baseId);

    const airtableConfig = {
      endpointUrl: "https://api.airtable.com",
      apiKey: apiKey,
    };

    Airtable.configure(airtableConfig);
    return Airtable.base(baseId);
  } else {
    console.error("API Key or Base ID is missing");
    return null;
  }
};

async function fetchData(base, year) {
  const storeBase = fetchBase();
  base = base ? base : storeBase;
  return new Promise((resolve, reject) => {
    const data = [];

    base("Monthly Revenue")
      .select({
        filterByFormula: `YEAR({Month Year}) = ${year}`,
      })
      .eachPage(
        async function page(records, fetchNextPage) {
          records.forEach(function (record) {
            data.push(record.fields);
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            reject(err);
            return;
          }
          resolve(data);
        }
      );
  });
}

async function fetchingMonthlyData(base, year, dispatch) {
  const storeBase = fetchBase();
  base = base ? base : storeBase;
  dispatch(requestStart());
  const data = await fetchData(base, year);
  dispatch(requestCompleted());

  return data;
}

async function fetchDataMonth(base, month) {
  if (!month) {
    throw new Error("Month is not selected");
  }
  const storeBase = fetchBase();
  base = base ? base : storeBase;
  return new Promise((resolve, reject) => {
    const filterFormula = `{monthRevID} = '${month}'`;

    base("Monthly Revenue")
      .select({
        filterByFormula: filterFormula,
      })
      .firstPage(function (err, records) {
        if (err) {
          console.error(err);
          reject(err);
          return;
        }
        const data = records.map((record) => record.fields);
        resolve(data);
      });
  });
}

async function fetchDataBilling(base, month) {
  if (!month) {
    throw new Error("Month is not selected");
  }
  const storeBase = fetchBase();
  base = base ? base : storeBase;
  return new Promise((resolve, reject) => {
    let allRecords = [];
    const filterFormula = `{mth.RevID} = '${month}'`;

    base("Booked Revenue")
      .select({
        filterByFormula: filterFormula,
      })
      .eachPage(
        async function page(records, fetchNextPage) {
          try {
            records.forEach(function (record) {
              allRecords.push({ ...record.fields, id: record.id });
            });
            fetchNextPage();
          } catch (err) {
            console.error(err);
            reject(err);
          }
        },
        function done(err) {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            resolve(allRecords);
          }
        }
      );
  });
}

export { fetchDataBilling, fetchDataMonth };

export default fetchingMonthlyData;
