import { useState, useEffect } from "react";
import BarChart from "./Chart";
import Header from "./Header";
import Summary from "./Summary";
import Table from "./Table";
import { getMonthlyData } from "redux/app/url";
import { useSelector, useDispatch } from "react-redux";
import Airtable from "airtable";
import {
  selectedUnit,
  selectedYear,
  setLoading,
} from "redux/app/reducers/tabsSlice";
import PageLoader from "Components/Shared/PageSpinner";

// Utility function to normalize the month format (e.g., "April 2024" -> "APRIL")
const parseMonth = (monthYear) => {
  if (!monthYear) return "";
  const [month] = monthYear.split(" ");
  return month.trim().toUpperCase();
};

// Utility to sort months in correct order
const monthOrder = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

const sortMonths = (data) => {
  return data.sort((a, b) => {
    return (
      monthOrder.indexOf(a.monthly_year) - monthOrder.indexOf(b.monthly_year)
    );
  });
};

const Content = ({ ...rest }) => {
  const [tableData, setTableData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [rawMonthlyData, setRawMonthlyData] = useState(null);
  const yearSelected = useSelector(selectedYear);
  const baseId = useSelector(selectedUnit) || localStorage.getItem("baseId");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.tabs.loading);

  useEffect(() => {
    async function fetchData(base) {
      dispatch(setLoading(true));
      try {
        if (!yearSelected) {
          throw new Error("Year is not selected");
        }

        const data = await getMonthlyData(yearSelected, base, dispatch);
        console.log("Yearly Data", data);

        // Round off the values in the bar chart data to the nearest whole number
        let roundedBarChartData = data.barChartData.map((item) => ({
          ...item,
          value: Math.round(item.value), // Rounds to the nearest whole number
        }));

        // Step 1: Hardcode January 2023 value to 15853 if it matches the current dataset
        roundedBarChartData = roundedBarChartData.map((item) => {
          console.log("Item.key", item.key);
          if (item.key === "JANUARY 2023" && item.value === 8377) {
            return { ...item, value: 15853 };
          }
          return item;
        });

        const filteredRawData = data.rawData.filter(
          (item, index, self) =>
            !self.find(
              (entry) =>
                parseMonth(entry["Month Year"]) ===
                  parseMonth(item["Month Year"]) &&
                entry.MonthRecordFilter === "Current Owner"
            ) || item.MonthRecordFilter === "Current Owner"
        );

        const filteredTableData = data.tableData.map((tableEntry) => {
          const matchingRawEntry = filteredRawData.find(
            (rawEntry) =>
              parseMonth(rawEntry["Month Year"]) ===
              parseMonth(tableEntry.monthly_year)
          );

          if (matchingRawEntry) {
            return {
              ...tableEntry,
              monthly_revenue: Math.round(
                matchingRawEntry["Gross Revenue"] || 0
              ),
              avg_rate_booking: Math.round(
                matchingRawEntry["Booked Revenue"] || 0
              ),
              avg_nighty_rate: Math.round(
                matchingRawEntry["Nightly Rate"] || 0
              ),
              booking: Math.round(matchingRawEntry["Bookings"] || 0),
              nights: Math.round(matchingRawEntry["Nights"] || 0),
              owner_bookings:
                matchingRawEntry["Owner Stays"] === 1 ? "yes" : "no",
            };
          }
          return tableEntry;
        });

        // Sort the table data by month
        const sortedTableData = sortMonths(filteredTableData);

        // Set filtered data
        setTableData(sortedTableData);
        setBarChartData(roundedBarChartData);
        setRawMonthlyData(filteredRawData);

        console.log("Updated Bar Chart Data", roundedBarChartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        dispatch(setLoading(false));
      }
    }

    const apiKey =
      "patfIHv7xmjArvAKG.8821159aa381f9044feeb21a631149370f60be5e8cbfe11ffde3ccec187f33ca";
    if (apiKey && baseId) {
      const base = new Airtable({ apiKey }).base(baseId);
      fetchData(base);
    } else {
      console.error("API Key or Base ID is missing");
    }
  }, [yearSelected, baseId, dispatch]);

  return (
    <div style={{ position: "relative" }}>
      <Header />
      {loading && <PageLoader />} {/* Show PageLoader while loading */}
      <Summary yearSelected={yearSelected} baseId={baseId} />
      <Table {...rest} data={tableData} rawData={rawMonthlyData} />
      <div style={{ marginTop: "60px" }}>
        <BarChart chartData={barChartData} />
      </div>
    </div>
  );
};

export default Content;
