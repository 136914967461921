import React, { useState } from "react";
import { Table } from "antd";
import "../table.css";
import BlueCheck from "Assets/Icons/BlueCheck";
import MonthlyPreview from "Pages/Dashboard/Modals/MonthlyPreview";
import formattedAmount from "util/formattedString";

const parseMonth = (monthYear) => {
  if (!monthYear) return "";
  const [month] = monthYear.split(" ");
  return month.trim().toUpperCase();
};

const columns = (rawData) => [
  {
    title: "Month",
    dataIndex: "monthly_year",
    key: "monthly_year",
    className: "boldColumn",
    render: (text) => (text ? text.toUpperCase() : ""),
  },
  {
    title: "Booking Rate",
    dataIndex: "avg_rate_booking",
    key: "avg_rate_booking",
    render: (text) => formattedAmount(text, true),
  },
  {
    title: "Nightly Rate",
    dataIndex: "avg_nighty_rate",
    key: "avg_nighty_rate",
    render: (text) => formattedAmount(text, true),
  },
  {
    title: "Bookings",
    dataIndex: "booking",
    key: "booking",
  },
  {
    title: "Nights",
    dataIndex: "nights",
    key: "nights",
  },
  {
    title: "Owner Bookings",
    dataIndex: "owner_bookings",
    className: "table-content-tick-icon",
    key: "owner_bookings",
    render: (text, record) => {
      console.log("Here is the raw in our table", rawData);

      if (!rawData) return <span className="empty-indicator">_</span>;

      const tableMonth = parseMonth(record.monthly_year);

      const rawDataEntries = rawData.filter(
        (item) => parseMonth(item.monthRevID) === tableMonth
      );

      if (rawDataEntries.length > 1) {
        console.log(`Multiple entries found for ${tableMonth}:`);
        rawDataEntries.forEach((entry) => {
          console.log(`Owner Type: ${entry.MonthRecordFilter}`, entry);
        });
      }

      const rawDataEntry =
        rawDataEntries.find(
          (item) => item.MonthRecordFilter === "Current Owner"
        ) || rawDataEntries[0];

      const ownerStay = rawDataEntry ? rawDataEntry["Owner Stays"] : 0;

      return ownerStay >= 1 ? (
        <div style={{ display: "flex", margin: "0 50px" }}>
          <BlueCheck color="#0098EE" className="table-content-tick-icon" />
        </div>
      ) : (
        <div style={{ display: "flex", margin: "0 50px" }}>
          <span className="empty-indicator">_</span>
        </div>
      );
    },
  },
];

const TableContent = ({ data, rawData, ...rest }) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  console.log("Here is the data in our table", data);
  console.log(rawData, "Here is the raw data in our table");

  const handleRowClick = (record) => {
    const { key } = record;
    setSelectedRowKey(key);

    if (key && rawData) {
      // Convert "MAY 2024" -> "MAY" to match rawData
      const rowData = rawData.filter(
        (item) => parseMonth(item.monthRevID) === parseMonth(key)
      );

      if (rowData.length > 1) {
        console.log(`Multiple entries found for ${key}:`, rowData);
      }

      const selectedRowData =
        rowData.find((item) => item.MonthRecordFilter === "Current Owner") ||
        rowData[0];

      setSelectedRowData(selectedRowData);
      console.log("Here is the row clicked data:", selectedRowData);
    }
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRowKey(null);
  };

  return (
    <div className="content-table-container">
      <Table
        dataSource={data}
        columns={columns(rawData)}
        pagination={false}
        rowClassName={(record) =>
          record.key === selectedRowKey && isModalVisible ? "selected-row" : ""
        }
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
      <MonthlyPreview
        {...rest}
        rawData={selectedRowData}
        visible={isModalVisible}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default TableContent;
