import React from "react";
import { MonthlyPreviewModal, PreviewModelWrapper } from "./styles";
import SubHeading from "Components/Shared/SubHeading";
import { Col, Row } from "antd";
import ModelDataCard from "Components/Shared/ModelDataCard";
import { data } from "./data";
import ModelSecondaryCard from "Components/Shared/ModelSecondaryCard";
import ButtonModel from "Components/Shared/ButtonModel";
import LinkIcon from "Assets/Icons/LinkIcon";
import formattedAmount from "util/formattedString";
import { removePMonthYear } from "util/get3MonthYear";
import ModelNavigation from "Components/Shared/ModelNavigation";
import { changeSelectedMonth } from "redux/app/reducers/tabsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Helper function to normalize rawData.monthID to YYYY-MM format
const formatMonthID = (monthYear) => {
  const date = new Date(`${monthYear} 1`);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${year}-${month}`;
};

// Helper function to check if the month is before or after the current month
const isBeforeOrEqualCurrentMonth = (monthID) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentMonthID = `${currentYear}-${currentMonth}`;

  // Compare monthID with the current month
  return monthID <= currentMonthID;
};

const formatValue = (value, isCurrency = false) => {
  if (value === null || value === undefined || value === "" || value === 0) {
    return isCurrency ? "$0" : "0";
  }
  if (isNaN(value)) {
    return value;
  }
  return isCurrency ? formattedAmount(value, true) : value;
};

const MonthlyPreview = ({ rawData, visible, onClose, setMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMonthlyPreview = () => {
    console.log("Raw Data we have in monthly statement => ", rawData);
    dispatch(changeSelectedMonth(rawData?.monthID));
    navigate("/monthly-statement");
  };

  const userType = localStorage.getItem("userType");
  const dropdownOptions =
    JSON.parse(localStorage.getItem("dropdownOptions")) || [];
  const normalizedMonthID = formatMonthID(rawData?.monthID);

  // Check if the month is in the dropdown options
  const isMonthInDropdown = dropdownOptions.some(
    (option) => option.value === normalizedMonthID
  );

  // Check if any of the relevant fields have a value (i.e., hasData is true)
  const hasData =
    rawData &&
    Object.values(rawData).some(
      (value) => typeof value === "number" && value > 0
    );

  // Check if the month is before or equal to the current month
  const isMonthBeforeCurrent = isBeforeOrEqualCurrentMonth(normalizedMonthID);

  // Logic for enabling/disabling the button:
  const isButtonEnabled =
    userType === "ADMIN" ? true : isMonthInDropdown && isMonthBeforeCurrent;

  console.log(
    `Button Enabled: ${isButtonEnabled} | User Type: ${userType} | Has Data: ${hasData} | Is Month In Dropdown: ${isMonthInDropdown} | Is Month Before Current: ${isMonthBeforeCurrent}`
  );

  return (
    <MonthlyPreviewModal
      visible={visible}
      onCancel={onClose}
      footer={null}
      mask={false}
    >
      <PreviewModelWrapper>
        <ModelNavigation onCloseModal={onClose} />
        <Row>
          <div className="model-title">
            {removePMonthYear(rawData?.monthlyID)} {rawData?.monthID}
          </div>
        </Row>
        <Row
          style={{
            marginBottom: "20px",
            background: "rgb(250, 250, 250)",
            borderRadius: "8px",
            padding: "20px",
            border: "1px solid #E7E7E7",
          }}
        >
          <Col md={12}>
            <SubHeading className="SubHeading">Rental Income</SubHeading>
          </Col>

          <Col xl={12} style={{ marginTop: "-17px" }}>
            {isButtonEnabled && (
              <div className="butn-holder" onClick={handleMonthlyPreview}>
                <ButtonModel>
                  View Monthly Statement{" "}
                  <div className="link-icon">
                    <LinkIcon />
                  </div>
                </ButtonModel>
              </div>
            )}
          </Col>
          <Col md={12}>
            <ModelSecondaryCard
              key="Gross"
              title="Gross revenue"
              value={formatValue(rawData?.["Gross Revenue"], true)}
            />
          </Col>
          {rawData?.["Gross Revenue"] ? (
            <>
              <Col md={12}>
                <ModelSecondaryCard
                  key="r_split"
                  title="Lodging tax"
                  value={formatValue(rawData?.["Lodging Tax"], true)}
                />
              </Col>
              <Col md={12}>
                <ModelSecondaryCard
                  key="Net_Taxes"
                  title="Net of taxes"
                  value={formatValue(rawData?.["Net of Taxes"], true)}
                />
              </Col>
              <Col md={12}>
                <ModelSecondaryCard
                  key="r_split"
                  title="Owner revenue"
                  value={formatValue(rawData?.["Owner Revenue"], true)}
                />
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>

        {data(rawData).map((item, index) => (
          <div key={item.key}>
            {index !== 2 && (
              <>
                <div className="model-data-row">
                  <SubHeading className="SubHeading">{item.title}</SubHeading>

                  <Row className="cards-holder">
                    {item.card.map((cardItem, cardIndex) => (
                      <Col md={11} key={cardIndex}>
                        <ModelDataCard
                          key={cardIndex}
                          title={cardItem.cardtitle}
                          value={formatValue(
                            cardItem.cardtitle === "Total nights" ||
                              cardItem.cardtitle === "Total bookings"
                              ? cardItem.cardvalue
                              : cardItem.cardvalue,
                            cardItem.cardtitle !== "Total nights" &&
                              cardItem.cardtitle !== "Total bookings"
                          )}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </>
            )}
          </div>
        ))}
      </PreviewModelWrapper>
    </MonthlyPreviewModal>
  );
};

export default MonthlyPreview;
