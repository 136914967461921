import React from "react";
import { MonthlyOverviewModal, MonthlyOverviewWrapper } from "./styles";
import { Checkbox, Col, Row } from "antd";
import ModelDataCard from "Components/Shared/ModelDataCard";
import { data as getData } from "./data";
import ModelSecondaryCard from "Components/Shared/ModelSecondaryCard";
import Frame from "Assets/Icons/Frame";
import SubHeading from "Components/Shared/SubHeading";
import formattedAmount from "util/formattedString";
import ModelNavigation from "Components/Shared/ModelNavigation";
import CheckIcon from "Assets/Icons/CheckIcon";
import YearIcon from "Assets/Icons/calendar_icon.svg";

const MonthlyOverview = ({ rowData, visible, onClose }) => {
  console.log("MonthlyOverview row data", rowData);

  if (!rowData) {
    return null;
  }

  // Extract the first element of the "Owner Stay" array
  const isOwnerOccupied =
    Array.isArray(rowData?.["Owner Stay"]) && rowData["Owner Stay"].length > 0
      ? rowData["Owner Stay"][0]
      : false;

  const data = getData(rowData);
  const monthlyRentalIncomeData = data[1];

  // Remove the calendar icon (📆) from the "Dates Occupied" string
  const datesOccupied = rowData?.["Dates Occupied"]?.replace("📆     ", "");

  return (
    <MonthlyOverviewModal
      visible={visible}
      onCancel={onClose}
      footer={null}
      mask={false}
    >
      <MonthlyOverviewWrapper>
        <ModelNavigation onCloseModal={onClose} />

        <Row>
          <div
            className="model-title"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={YearIcon}
              alt="Calendar Icon"
              style={{
                marginRight: "8px",
                width: "24px",
                height: "24px",
              }}
            />
            {datesOccupied}
          </div>
        </Row>

        {data.map((item, index) => (
          <div key={item.key}>
            {index !== 1 && (
              <Row className="card-holder">
                {item.card.map((cardItem, cardIndex) => (
                  <Col md={12} key={cardIndex}>
                    <ModelDataCard
                      title={cardItem.cardtitle}
                      value={cardItem.cardvalue}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </div>
        ))}

        <div className="rentel-income-card-holder">
          <SubHeading className="SubHeading">Rental Income</SubHeading>
          {isOwnerOccupied ? (
            <Row>
              <div className="owner-occupied-row">
                <div className="card-title">Owner  stay</div>
                <div className="card-title">Value of owner's booking</div>
              </div>
              <Row className="owner-occupied-2nd-row">
                <div className="frame-holder">
                  {isOwnerOccupied ? <CheckIcon /> : <Frame />}
                </div>

                <div className="card-value">
                  {formattedAmount(rowData?.["Owner Booking Value"], true)}
                </div>
              </Row>
            </Row>
          ) : (
            <Row>
              {monthlyRentalIncomeData.card.map((cardItem, cardIndex) => (
                <Col xl={12} key={cardIndex}>
                  <ModelSecondaryCard
                    title={cardItem.cardtitle}
                    value={cardItem.cardvalue}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </MonthlyOverviewWrapper>
    </MonthlyOverviewModal>
  );
};

export default MonthlyOverview;
