import React from "react";
import formattedAmount from "util/formattedString";
import SingleCard from "./SingleCard";

const OtherRows = ({ data }) => {
  const formatValue = (value, isCurrency = false) => {
    if (value === null || value === undefined || value === "") {
      return isCurrency ? "$0" : "0";
    }
    return isCurrency ? formattedAmount(value, true) : value;
  };

  const baseId = localStorage.getItem("baseId");

  const valuePerOwnerBooking = formatValue(
    baseId === "appkscyvJutKmwgNB"
      ? data?.["Owner Booking Rate"]
      : data?.["Owner Booking Rate"],
    true
  );

  const valueOfOwnerBookings = formatValue(
    baseId === "appkscyvJutKmwgNB"
      ? data?.["Val of Owner Booking"]
      : data?.["Val of Owner Booking"],
    true
  );

  return (
    <div className="OtherRowsContainr">
      <div className="summary-2x-row">
        <SingleCard
          fValue={formatValue(data?.["Bookings"])}
          sValue={formatValue(data?.["Booking Rate"], true)}
          fTitle="Total Bookings"
          sTitle="Rate per Booking"
        />
        <SingleCard
          fValue={formatValue(data?.["Guest Nights"])}
          sValue={formatValue(data?.["Guest Nightly Rate"], true)}
          fTitle="Guest Nights"
          sTitle="Rate per Guest Night"
        />
      </div>
      <div className="summary-2x-row">
        <SingleCard
          fValue={`${formatValue(data?.["Owner Bookings"])}/${formatValue(
            data?.["Owner Nights"]
          )}`}
          sValue={valuePerOwnerBooking}
          fTitle="Owner bookings / nights"
          sTitle="Value per Owner Booking"
        />
        <SingleCard
          fValue={valueOfOwnerBookings}
          sValue={formatValue(data?.["Owner Nightly Rate"], true)}
          fTitle="Value of owner booking(s)"
          sTitle="Value per owner Night"
        />
      </div>
    </div>
  );
};

export default OtherRows;
