import React from "react";
import formattedAmount from "util/formattedString";
import { Badge } from "antd";

export const data = (val) => {
  console.log("val we have ", val);

  const splitRsvpDates = val?.["SplitRsvpDates"]?.[0]; // Use SplitRsvpDates field
  const bookedRevID = val?.["bookedRevID"];

  // Add logs to better understand the structure of bookedRevID
  console.log("bookedRevID:", bookedRevID);

  // Ensure bookedRevID exists and split the value by "_"
  let hasSuffix = false;
  if (bookedRevID) {
    const parts = bookedRevID.split("_");
    console.log("Split bookedRevID parts:", parts);

    // Check if the last part is a digit, and ignore initial parts like '_direct'
    if (parts.length > 1 && /^\d+$/.test(parts[parts.length - 1])) {
      hasSuffix = true;
      console.log("Suffix found:", parts[parts.length - 1]);
    } else {
      console.log("No valid suffix found in bookedRevID.");
    }
  }

  // Custom trim function to remove unwanted characters
  const cleanGuestName = (name) => {
    return name ? name.replace(/[*#\\]/g, "").trim() : "";
  };

  const cards = [
    {
      cardtitle: "Guest",
      cardvalue: cleanGuestName(val?.["Guest"]),
    },
    { cardtitle: "Nights", cardvalue: val?.["Nights"] ?? "-" },
    {
      cardtitle: "Room",
      cardvalue: val?.["Unit No"] ?? "-",
    },
    {
      cardtitle: "Year",
      cardvalue: val?.["Year"]?.replace(/[*#]/g, "").trim(),
    },
  ];

  // Display "Total dates reserved" and "EOM stay" only if bookedRevID has a suffix
  if (hasSuffix) {
    console.log("Condition met for displaying EOM and total reserved dates.");

    // Use SplitRsvpDates for "Total dates reserved"
    if (splitRsvpDates) {
      cards.push({
        cardtitle: "Total dates reserved",
        cardvalue: splitRsvpDates,
      });
    }

    cards.push({
      cardtitle: "EOM stay",
      cardvalue: (
        <span
          style={{
            display: "inline-block",
            width: "15px", // Adjust the size of the circle here
            height: "15px",
            backgroundColor: "#1E40AF", // Same dark blue color
            borderRadius: "50%", // Ensures it's a perfect circle
          }}
        />
      ),
    });
  } else {
    console.log("Condition not met; skipping EOM and total reserved dates.");
  }

  return [
    {
      key: 1,
      card: cards,
    },
    {
      key: 3,
      card: [
        {
          cardtitle: "Booked revenue",
          cardvalue:
            val?.["Booked Revenue"] && val?.["Booked Revenue"] !== 0
              ? formattedAmount(val?.["Booked Revenue"], false)
              : "$0",
        },
        {
          cardtitle: "Lodging tax",
          cardvalue:
            val?.["Lodging Tax"] && val?.["Lodging Tax"] !== 0
              ? formattedAmount(val?.["Lodging Tax"], false)
              : "$0",
        },
        {
          cardtitle: "Net of taxes",
          cardvalue:
            val?.["Net Revenue"] && val?.["Net Revenue"] !== 0
              ? formattedAmount(val?.["Net Revenue"], false)
              : "$0",
        },
        {
          cardtitle: "Owner revenue",
          cardvalue:
            val?.["Owner Revenue"] && val?.["Owner Revenue"] !== 0
              ? formattedAmount(val?.["Owner Revenue"], false)
              : "$0",
        },
      ],
    },
  ];
};
