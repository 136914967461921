import styled from "styled-components";
import { Modal } from "antd";

const cardStyles = `
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #fafafa;
  border: 1px solid #e7e7e7;
`;

export const MonthlyOverviewWrapper = styled.div`
  padding: 12px 0px 10px;
  display: flex;
  flex-direction: column;

  &.center {
    text-align: center;
  }

  .cards-holder {
    ${cardStyles}
    display: flex;
    flex-direction: row;

    gap: 8px;
  }

  .card-holder {
    ${cardStyles}
  }
`;

export const MonthlyOverviewModal = styled(Modal)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 720px;
  }

  .ant-modal-content {
    height: 120vh;
    min-width: 720px;
    width: 100%;
    border-radius: 0;
    padding: 0px 44px;
    overflow: scroll;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    display: none;
  }

  .green-circle {
    padding: 3px 4px;
    width: fit-content;
  }

  .butn-holder {
    display: flex;
    justify-content: end;
    align-items: end;
    height: 100%;
  }

  .owner-rev-card {
    ${cardStyles}
    padding: 12px 0px 10px !important;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    gap: 66px;
    margin-top: 22px;
    width: 100%;
  }

  .owner-occupied-card {
    ${cardStyles}
    width: 100%;
    margin-top: 12px;
  }

  .owner-occupied-2nd-row {
    padding: 0px 0px 13px !important;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    gap: 66px;
    width: 100%;
  }
  .owner-occupied-row {
    padding: 0px !important;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    gap: 66px;
    width: 100%;
  }

  .owner-occupied-2nd-row {
    gap: 100px;
  }

  .frame-holder {
    margin-right: 63px;
  }

  .card-title {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  .card-value {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    // min-width: 105px;
  }

  .model-title {
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 16px;
    font-size: 29px;
    line-height: 20px;
    font-weight: 850;
    font-family: "Montserrat";
    color: #110f0e;
    border-bottom: 1px solid #cccccc;
    margin-top: 40px;
  }

  .rentel-income-card-holder {
    ${cardStyles}
    .card-value {
      width: 50%;
    }
  }

  .full-width-card {
    width: 100%;
    margin-bottom: 40px;
    .card-title {
      margin-bottom: 12px;
    }
  }
`;
